import axios from "axios";
import {Base64, fromBase64} from "js-base64";
import { ElMessageBox } from 'element-plus'
import router from "@/router";


//创建通用axios配置
let instance = axios.create({
    responseType:'json',
    headers:{'Content-Type':'application/json'}
})

function basfun(){
    let token = localStorage.getItem("token")
    // let base64 =  Base64.encode(token + ":");

    return token;
}
//http:拦截：是在axios请求发出之前给每一个接口携带token
instance.interceptors.request.use(
    config=>{
        let token = localStorage.getItem("token")
        if(token){
            config.headers['token']=basfun();
        }
        if(config.url.includes("dealExcelGoods")){
            config.headers['Content-Type']='multipart/form-data;'
        }
        return config;
    },
    err=>{
        return Promise.reject(err);
    }
)

//http:拦截：是在axios请求发出之后处理
instance.interceptors.response.use(
    response=>{
        if(response.data.code===401){
            //没有访问权限，token过期，没有携带token请求，token错误
            ElMessageBox.alert(response.data.msg, '提示', {
                confirmButtonText: '好的',
                type:"error"
            })
                .then(res=>{ //点击好的进入该回调
                    //跳转到登录界面
                    router.push({name:"login"})
                })
                .catch(err=>{//点击关闭按钮进入该回调
                    router.push({name:"login"})
                })
        }
        return response;
    },
    error => {
        if(error.response){
            let status =  error.response.data.code;
            let msg = error.response.data.msg;
            let errData = status == 401 ? msg:"服务器发生错误"
            switch (status){
                case 401:
                    //没有访问权限，token过期，没有携带token请求，token错误
                    ElMessageBox.alert(msg, '提示', {
                        confirmButtonText: '好的',
                        type:"error"
                    })
                        .then(res=>{ //点击好的进入该回调
                            //跳转到登录界面
                            router.push({name:"login"})
                        })
                        .catch(err=>{//点击关闭按钮进入该回调
                            router.push({name:"login"})
                        })
                    break;
            }
        }
        return Promise.reject(error.response.data)//返回接口的错误信息
    }
)

export default instance