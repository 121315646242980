//放置所有的请求接口

const url='https://www.zuolingyoushi.com/mini'; //生产地址
// const url='https://zuolingyoushi.free.svipss.top/mini'; //测试地址
// const url='http://localhost:8081/mini';
const urls=class  {
    static m(){
        //登录接口
        const login=`${url}/zlysManageUser/doLogin`
        const createGoods=`${url}/manageGoods/createGoods`
        const goodsPageList=`${url}/manageGoods/pageList`
        const goodsDelete=`${url}/manageGoods/delete`
        const memberList=`${url}/manageMember/memberList`
        const addCoupon=`${url}/manageCoupon/addCoupon`
        const getCouponList=`${url}/manageCoupon/getCouponList`
        const manageCouponGetById=`${url}/manageCoupon/getById`
        const addUserCoupon=`${url}/manageUserCoupon/addUserCoupon`
        const downloadCoupon=`${url}/manageCoupon/downloadCoupon`
        const getUserCouponList=`${url}/manageUserCoupon/getUserCouponList`
        const downUserCoupon=`${url}/manageUserCoupon/downUserCoupon`
        const dealExcelGoods=`${url}/manageGoods/dealExcelGoods`
        return {
            login,
            createGoods,
            goodsPageList,
            goodsDelete,
            memberList,
            addCoupon,
            getCouponList,
            manageCouponGetById,
            addUserCoupon,
            downloadCoupon,
            getUserCouponList,
            downUserCoupon,
            dealExcelGoods
        }
    }
}
export default urls;